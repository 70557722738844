import "./Note.css"
import React, {ReactNode} from "react";
type Props = {
    className?:  string
    children: ReactNode
}
const Note: React.FC<Props> = (props:Props): JSX.Element =>{
    return (<p className={`note ${props.className}`}>💡{props.children}</p>)
}

export default Note

