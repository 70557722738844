import React from "react";
import {Spinner} from "react-bootstrap";

export const PageLoader = () => {

  return (
    <div className="loader">
      <Spinner></Spinner>
    </div>
  );
};
