import React from "react";
import DashBoard from "./DashBoard";
import {PageLayout} from "../components/PageLayout";



export const DashBoardPage = () => (
    <PageLayout>
       <DashBoard></DashBoard>
    </PageLayout>
);

export default DashBoardPage
