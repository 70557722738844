// in src/admin/index.tsx

import 'bootstrap/dist/css/bootstrap.css';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import "./Admin.css"
import "./Utility.css"
import React, {useEffect, useState} from "react";
import {Customer, State} from "./Customer";
import {useAuth0} from "@auth0/auth0-react";
import fetcher from "../utils/useApi";
import Note from "../components/notes/Note";
import {Collapse, OverlayTrigger, Tooltip} from "react-bootstrap";
import QRCodeLink from "../components/links/QRCodeLink";

interface ICustomer {
    id: string,
    started_waiting_pretty: string,
    state: number,
    started_waiting: string
}

const DashBoard = () => {
    const [customers, setCustomers] = useState([]);
    const {getAccessTokenSilently} = useAuth0();
    const [refresher, triggerRefresher] = useState(0)
    const sortCustomers = (customers: [ICustomer]) => {
        if (customers) {
            customers.sort((a, b) => {
                return a.started_waiting > b.started_waiting ? 1 : -1
            })
        }
        setCustomers(customers as unknown as any)
    }

    useEffect(() => {
        const myInterval = setInterval(() => triggerRefresher(Date.now()), 5000);

        return () => {
            // should clear the interval when the component unmounts
            clearInterval(myInterval);
        };
    }, []);
    useEffect(() => {
        (async () => {
            try {
                // The token is not refreshed if it is valid, getAccessTokenSilently takes care of this
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://api.qbox.no',
                        scope: "admin:queue",
                    },
                });
                const response = await fetcher("/api/users", accessToken)
                sortCustomers(await response.json())
            } catch (e: any) {
                console.log(e.message)
            }
        })()
    }, [getAccessTokenSilently, refresher]);

    const notifyCustomer = async (state: State, customerID: string) => {
        try {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: 'https://api.qbox.no',
                    scope: "admin:queue",
                },
            });
            fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/notify`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                body: JSON.stringify({state: state, customer_id: customerID})
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(
                            `This is an HTTP error: The status is ${response.status}`
                        );
                    }
                    return response
                })
        } catch (e: any) {
            console.error(e.message)
        }
    }

    const [open] = useState(true);
    return (
        <div>
            <div className="my-5">

                {customers && customers.length > 0 &&


                    <div className="table-wrapper">
                        <div>
                            <h2>Queue Status: </h2><p>Highest up is next in turn</p>
                        </div>
                        <table className="table table-striped">
                            <thead className="thead-dark">

                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Waited Since</th>
                                <th scope="col" className="hide-when-viewport-sm">Status</th>
                                <th>
                                    <OverlayTrigger
                                        placement={'top'}
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                            <Tooltip>
                                                Pressing CALL will tell the customer they
                                                are ready to be treated. If this was a mistake,
                                                you can press UNDO to reset. The customer will get
                                                back in the queue. Sending "FINISHED" will send a finished message
                                                and then remove the user from the queue.
                                            </Tooltip>
                                        }
                                    >
                                        <span>Handle customer actions</span>
                                    </OverlayTrigger>

                                </th>
                                <th scope="col">
                                    <OverlayTrigger
                                        placement={'top'}
                                        delay={{show: 250, hide: 400}}
                                        overlay={
                                            <Tooltip>
                                                Pressing Removed here will
                                                remove the user from the queue.
                                            </Tooltip>
                                        }
                                    >
                                        <span>Remove for queue or uncall</span>
                                    </OverlayTrigger>
                                </th>
                            </tr>

                            </thead>

                            <tbody>
                            {customers &&

                                customers.map((customer: ICustomer) => (
                                    <Customer id={customer.id}
                                              key={customer.id}
                                              startedWaitingPretty={customer.started_waiting_pretty}
                                              startedWaiting={customer.started_waiting}
                                              state={customer.state}
                                              updateCustomerStateFn={notifyCustomer}></Customer>


                                ))}
                            </tbody>

                        </table>
                        <br/><br/>


                    </div>
                }
            </div>
            <div className="container">
                {/*            <Button
                className="mb-4"
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
            >{open ? "Hide this section" : "How to get started"}</Button>*/}
                <Collapse in={open}>
                    <div>
                        <h1>How to start</h1>
                        <p className="fs-4">Visit this link <QRCodeLink/> and display it for your customers.

                            When they scan the QR code shown, they will appear here in a dashboard for you to interact
                            with!🤩</p>

                        <Note className="fs-6">Try clicking the link and scan the QR code shown with your phone.
                            Then come back to this dashboard page and you will see that someone is in the queue.
                            You will
                            quickly understand how it works.</Note>
                        <p className="fs-5">See <a className="link-info" href="/howto">how-to-use</a> page for more info
                            and customizations. </p>
                    </div>

                </Collapse>

            </div>
        </div>


    );
}

export default DashBoard;
