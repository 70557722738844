import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "./LandingPage.css"
import {LoginButton} from "../components/buttons/LoginButton";
import iphone from "../images/iphone.png"
import qrsvg from "../images/qr-svg.svg"
import dashboardimg from "../images/dashboard.png"

export const LandingPage = () => {
    let iphoneHeight = 400
    return (
        <div id="main" className="container-fluid">
            <div className="container p-xl-5">
                <div className="row d-flex justify-content-center text-center">
                    <div className="col">
                        <div className="container align-middle spacer-up">

                        </div>
                        <div className="container align-middle p-5 jump-in">
                            <h1 className="fw-bold text-light ">You are one minute away from solving your queue problems</h1>
                            <p className="fs-4">
                                QBox enables you to get control of your queues in a new way.
                                Just login here to get started, and you will be up and running in less than a minute.
                            </p>


                            <LoginButton></LoginButton>
                        </div>
                    </div>
                    <div className="col">
                        <div className="container align-middle spacer-up">

                        </div>
                        <div className="container">
                            <div className="iphone-container float-image">
                                <img alt="" className="qr-background" src={iphone} width={300}
                                     />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="container p-xl-5 spacer-up">
                {/*eslint-disable-next-line */ }
                <a id="how-it-works"></a>
                <div className="row d-flex justify-content-center text-center">

                    <div className="col">
                        <div className="container">
                            <div className="rounded">
                                <img alt="" className="qr-background" src={qrsvg}
                                     width={300}
                                     height={iphoneHeight}/></div>

                        </div>
                    </div>

                    <div className="col">
                        <div className="container align-middle p-5 jump-in " >

                            <h1 className="fw-bold text-light ">1. You get a QR code like this that represents your
                                shop</h1>
                            <p className="fs-4">
                                The customers scan this QR code to get into a virtual queue
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <a  href="#how-it-works" className="scroll-down-link scroll-down-arrow mt-1" data-iconfont="ETmodules" data-icon>
            </a>
            <div className="container p-xl-5">
                <div className="row d-flex justify-content-center text-center">
                    <div className="col">
                        <div className="container align-middle p-5 jump-in">
                            <h1 className="fw-bold text-light ">2. Your customers get put in a virtual queue on their
                                phone</h1>
                            <p className="fs-4">
                                Here they can see their expected waiting time and their number in the queue, as
                                well as customized messages that you can choose.
                                You can also customize the logo here.
                            </p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="container">
                            <img alt="" className="qr-background" src={iphone} width={300}/>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container p-xl-5">
                <div className="row d-flex justify-content-center text-center">
                    <div className="col">
                        <div className="container p-5">
                            <img alt="" className="qr-background" src={dashboardimg} width={500}/>
                        </div>
                    </div>
                    <div className="col">
                        <div className="container align-middle p-5 jump-in">
                            <h1 className="fw-bold text-light ">3. You get an overview of who has joined your queue</h1>
                            <p className="fs-4">
                                Here you can send events to the people in the queue. Pressing CALL here would notify the user that it's their turn.
                                You can run the queue in "auto" mode where the first in the queue automatically gets the notification that they should come
                                to the desk.
                            </p>
                            <p>
                                If you are serving food you can use the call function to call only when food is ready. So you let the people scan the QR code after
                                they order, and register the ID that they got together with the order.</p>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

