import React from 'react'
import "../components/buttons/Button.css"
import "./Customer.css"
import "./Utility.css"
import CircleWithNumber from "./CircleWithNumber";

export interface ICustomer {
    id: string;
    startedWaitingPretty: string;
    startedWaiting?: string
    restaurantID?: string
    state: State;
    updateCustomerStateFn: (a: State, id: string) => Promise<any>
}

enum State {
    NOT_READY = 1,
    ALMOST_READY,
    READY,
    PERMANENTLY_REMOVED,
    FINISHED,
    FINISHED_AND_REMOVED,
}

function GetHowLongSince(dateString: string | undefined): string {
    if (dateString) {
        const date = new Date(dateString);
        const now = new Date();

        const diffMs = now.getTime() - date.getTime(); // difference in milliseconds
        const diffMins = Math.floor(diffMs / 1000 / 60); // difference in minutes
        const diffHrs = Math.floor(diffMs / 1000 / 60 / 60); // difference in hours
        // Format pretty depending on if we are over 1 hour
        return diffHrs > 0 ? `${diffHrs}h` : `${diffMins}m`
    }
    return ""
}

const Customer = function Customer(props: ICustomer) {
    const [customerState, setCustomerState] = React.useState(props.state);

    function getBackgroundClass(state: State): string {
        switch (state) {
            case State.READY:
                return "bg-ready"
            default:
                return ""
        }
    }

    if (customerState === State.PERMANENTLY_REMOVED || customerState === State.FINISHED ) {
        return <></>
    } else {
        return (
            <tr key={props.id} className={`${getBackgroundClass(customerState)}`}>

                <td><CircleWithNumber id={props.id}/></td>
                <td>{`${props.startedWaitingPretty} (${GetHowLongSince(props.startedWaiting)})`}</td>

                <td className="hide-when-viewport-sm"><span
                    className="status blink_me text-success">&bull;</span> Active
                </td>
                <td>
                    <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                        <button onClick={() => {
                            props.updateCustomerStateFn(State.READY, props.id);
                            setCustomerState(State.READY)
                        }} className="cool-button ready me-2"><span>Call</span><i></i></button>

                        <button onClick={() => {
                            props.updateCustomerStateFn(State.FINISHED, props.id);
                            setCustomerState(State.FINISHED)
                        }} className="cool-button finished me-2" ><span>Finished</span><i></i>
                        </button>


                    </div>
                </td>
                <td>

                    <button onClick={() => {
                        props.updateCustomerStateFn(State.NOT_READY, props.id);
                        setCustomerState(State.NOT_READY)
                    }} className="btn btn-info" name="notready"><span>Reset call</span><i></i>
                    </button>
                    <button onClick={() => {
                        props.updateCustomerStateFn(State.PERMANENTLY_REMOVED, props.id);
                        setCustomerState(State.PERMANENTLY_REMOVED)
                    }} className="btn btn-danger m-1" name="permanentlyremoved" value="1">Remove
                    </button>

                </td>

            </tr>

        )
    }
}

export {Customer, State, GetHowLongSince}
