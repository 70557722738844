
import {Route, Routes} from "react-router-dom";
import SettingsPage from "./settingspage/SettingsPage";
import {CallbackPage} from "./pages/callback-page";
import {NotFoundPage} from "./pages/NotFoundPage";
import {useAuth0} from "@auth0/auth0-react";
import {PageLoader} from "./components/page-loader";

import {AuthenticationGuard} from "./components/authentication-guard";
import DashBoardPage from "./admin/DashBoardPage";
import {LandingPage} from "./landingpage/LandingPage";
import {QueryClient, QueryClientProvider} from 'react-query'
import HowToPage from "./howtopage/howto";




const queryClient = new QueryClient();

function App() {
    const {isLoading} = useAuth0();
    if (isLoading) {
        return (
            <PageLoader/>
        );
    }
    return (
        <QueryClientProvider client={queryClient}>
        <Routes>
            <Route path="/" element={<LandingPage/>}/>
            <Route
                path={"/dashboard"}
                element={<AuthenticationGuard component={DashBoardPage}/>}
            />
            <Route
                path={"/settings"}
                element={<AuthenticationGuard component={SettingsPage}/>}
            />
            <Route path={"/howto"} element={ <HowToPage/>}>

            </Route>
            <Route path={"/callback"} element={<CallbackPage/>}></Route>
            <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
        </QueryClientProvider>
    );
}

export default App;
