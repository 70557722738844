import {PageLayout} from "../components/PageLayout";
import dashboard from "../images/dashboard.png"
import exampleWaitingroom from "../images/waiting-room-example.png"
import React from "react";
import Note from "../components/notes/Note";
import QRCodeLink from "../components/links/QRCodeLink";

const HowToPage = () => {

    return (
        <PageLayout>
            <div className="container my-5 fs-5">
                <h2>How to use QBox</h2>
                <Note className="fs-6"> The text that the user sees are customizeable. See
                    <a className="ms-sm-1 link-info"
                       href="/settings#settings">Settings</a>
                </Note>
                <p>
                    When the customers scan the QR code {
                   <QRCodeLink/>
                }
                    , they get to a waiting room screen which shows their
                    number in the queue as well as provides updates of expected waiting time and how many is before
                    them.
                </p>
                <ol className="list-style-decimal ps-4 mt-2">
                    <li className="mb-3">
                        Press "READY" for the user you want to treat next (the highest one in the list has
                        waited the longest).
                        You'll see a view like this:
                        <img className="m-3" src={dashboard} width={700} alt=""/> <br/>
                        Wait until one of the customer comes to the desk.
                        Verify that their ID on the phone screen is the same one you have pressed "READY"
                        for. A screen like this will shown for the customer: <br/>
                        <img className="m-3" src={exampleWaitingroom} width={200} alt=""/>
                    </li>
                    <li className="mb-3">
                        Handle their request (buying food, renting/buying equipment, or whatever it may be
                        for your shop).
                    </li>
                    <li className="mb-3">
                        Press "FINISHED" on the Queue overview for that user. This will send a message to
                        the customer that they have been finished.
                    </li>
                    <li className="mb-3">
                        Remove the customer by pressing "REMOVE".
                    </li>
                    <li className="mb-3">
                        If the person doesn't show up, even though they are first in the queue, drop the
                        person by doing "REMOVE". This will send a message notifying the person that they
                        have been removed from the queue.
                    </li>
                    <li className="mb-3">
                        Every time someone is removed from the queue, everyone currently in the queue will
                        get a status change that tells them they have been bumped up one step in the queue.
                        When they are top 3 in the queue, a special message will be sent telling them that
                        it is soon their turn.
                    </li>
                    <li className="mb-3">
                        Success! Your customers learns that they can move around your shop and trust they get help when
                        its their turn.
                    </li>
                </ol>
            </div>
        </PageLayout>
    )

}

export default HowToPage
