import {useAuth0} from "@auth0/auth0-react";
import React from "react";
import "./Button.css"

export const LogoutButton = () => {
    const {logout} = useAuth0();

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };

    return (
        <button className="btn btn-light" onClick={handleLogout}>
            <span>Logout</span><i></i>
        </button>
    );
};
