import React, {useState} from "react";

import {useQuery} from "react-query";
import fetcher from "../utils/useApi";
import {useAuth0} from "@auth0/auth0-react";
import Spinner from "../components/spinners/Spinner";
import Note from "../components/notes/Note";
import {PageLayout} from "../components/PageLayout";
import Toaster from "./Toaster";
import QRCodeLink from "../components/links/QRCodeLink";
const SettingsPage = function UserPage() {

    return (
        <PageLayout>
            <div className="container my-4 ">
                <div className="jumbotron jumbotron-fluid">
                    <div className="container my-4">
                        <h1 className="display-4">Settings</h1>
                        <p className="lead">
                            This is the customization page which helps you personalize the vibe and feel of your shop

                        </p>
                        <p className="fs-5"> If you just want to get started, the settings can be updated down <a
                            className="link-info" href="#settings">here</a></p>

                        <Note>
                            Tip! You can use emojis below. This is a good place to find which ones to use: <a
                            target="_blank" rel="noreferrer"
                            href="https://emojipedia.org/person-climbing/">Emojipedia</a>
                        </Note>

                    </div>
                </div>
                <div className="container my-4">

                    {
                        // Used to reference within the page
                        // eslint-disable-next-line
                        <a id="settings"></a>
                    }
                    <SettingsForm></SettingsForm>
                </div>

            </div>
        </PageLayout>

    )
}

export default SettingsPage

interface TRestaurantData {
    id: string
    restaurant_identifier: string
    subscription_level: string
    settings: TRestaurantSettings

}

interface TRestaurantSettings {
    name: string
    logo_url: string
    place_holder_data: Record<string, string>
}



const SettingsForm: () => JSX.Element = () => {
    const {getAccessTokenSilently} = useAuth0()
    const [toastershow, setToastershow] = useState(false);
    const {isLoading, error} = useQuery<TRestaurantData, Error>(['/api/restaurant'], async () => {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: 'https://api.qbox.no',
                    scope: "admin:queue",
                },
            });


            return await fetcher("/api/restaurant", accessToken,).then((res) => res.json())


        },
        {
            onSuccess: data => {
                setNotReadyMessage(data.settings.place_holder_data.not_ready_message)
                setAlmostReadyMessage(data.settings.place_holder_data.almost_ready_message)
                setFinishedMessage(data.settings.place_holder_data.finished_message)
                setPermanentlyRemovedMessage(data.settings.place_holder_data.permanently_removed_message)
                setReadyMessage(data.settings.place_holder_data.ready_message)
                setRestaurantName(data.settings.name)
                setLogoURL(data.settings.logo_url)
            }
        }
    )

    const [not_ready_message, setNotReadyMessage] = useState<string>("");
    const [almost_ready_message, setAlmostReadyMessage] = useState<string>("");
    const [finished_message, setFinishedMessage] = useState<string>("");
    const [permanently_removed_message, setPermanentlyRemovedMessage] = useState<string>("");
    const [ready_message, setReadyMessage] = useState<string>("");
    const [restaurant_name, setRestaurantName] = useState<string>("");
    const [logo_url, setLogoURL] = useState<string>("");
    if (isLoading) return <Spinner></Spinner>

    if (error) return <div><h1>Something went wrong when loading data. Try refresh page.</h1></div>
    // Takes a useState function and wraps it such that the ChangeEvent target
    // is passed to it
    const Wrap = (cb: (s: string) => void) => {
        return (event:React.ChangeEvent<HTMLInputElement>) => {
            cb(event.target.value)
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: 'https://api.qbox.no',
                scope: "admin:queue",
            },
        });
        const restaurantSettings: TRestaurantSettings = {
            name: restaurant_name,
            logo_url: logo_url,
            place_holder_data: {
                not_ready_message: not_ready_message,
                almost_ready_message: almost_ready_message,
                finished_message: finished_message,
                ready_message: ready_message,
                permanently_removed_message: permanently_removed_message
            }
        }
        console.log("sending: ", JSON.stringify(restaurantSettings))
        fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/restaurant`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(restaurantSettings),
        }).then(response => response.status === 204).catch(error => {
            console.log('Error:', error);
        });
    };

    if (isLoading) {
        return (
            <Spinner/>
        )
    } else {
        return (
            <div className="container">
            <form onSubmit={handleSubmit} className="my-4">
                <h3>Name of your enterprise</h3>
                <div className="form-group  my-3">
                    <label className="mb-2" htmlFor="restaurant_name">Name of your enterprise,  will be shown in the user waiting room</label>
                    <input
                        type="text"
                        id="restaurant_name"
                        value={restaurant_name}
                        onChange={Wrap(setRestaurantName)}
                        className="form-control"
                        maxLength={500}
                    />
                </div>
                <div className=" form-group  my-3">
                    <label className="mb-2" htmlFor="logo_url">Logo URL (shown for the user in the virtual waitingroom). For example: https://website.qbox.no/favicon.ico</label>
                    <input
                        type="text"
                        id="logo_url"
                        value={logo_url}
                        onChange={Wrap(setLogoURL)}
                        className="form-control"
                        maxLength={200}
                    />
                </div>
                <br></br>
                <h3>Waiting message</h3>
                <div className="form-group  my-3">
                    <label className="mb-2" htmlFor="not_ready_message">Shown for user when they are waiting in queue</label>
                    <input
                        type="text"
                        id="not_ready_message"
                        value={not_ready_message}
                        onChange={Wrap(setNotReadyMessage)}
                        className="form-control"
                        maxLength={60}
                    />
                </div>
                <div className="form-group  my-3">
                    <label className="mb-2" htmlFor="almost_ready_message">Shown for users when they are in top 3 of
                        queue </label>
                    <input
                        type="text"
                        id="almost_ready_message"
                        value={almost_ready_message}
                        onChange={Wrap(setAlmostReadyMessage)}
                        className="form-control"
                        maxLength={60}
                    />

                </div>
                <div className="form-group  my-3">
                    <label className="mb-2" htmlFor="ready_message">Shown for users when you press READY </label>
                    <input
                        type="text"
                        id="ready_message"
                        value={ready_message}
                        onChange={Wrap(setReadyMessage)}
                        className="form-control"
                        maxLength={60}
                    />

                </div>
                <div className=" form-group  my-3">
                    <label className="mb-2" htmlFor="finished_message">Shown for users after you have sent the "FINISHED"
                        notification to
                        them</label>
                    <input
                        type="text"
                        id="finished_message"
                        value={finished_message}
                        onChange={Wrap(setFinishedMessage)}
                        className="form-control"
                        maxLength={60}
                    />
                </div>

                <div className="form-group  my-3">
                    <label className="mb-2" htmlFor="permanently_removed">Shown for users when you remove them from queue, because they didn't show up.</label>
                    <input
                        type="text"
                        id="permanently_removed"
                        value={permanently_removed_message}
                        onChange={Wrap(setPermanentlyRemovedMessage)}
                        className="form-control"
                        maxLength={60}
                    />

                </div>

                <button type="submit" className="btn btn-primary">Save Settings</button>
            </form>
                <div className="my-5">
                    <h2>Danger Zone</h2>
                    <p className="fs-5">
                        Generate new QR code. People in the queue will still be in the queue, but the old QR code will not work for scanning.
                        If you have printed it out, you will have to do this again. A new QR code will be generated for you which will work!
                    </p>
                    <button onClick={async (e) => {
                        e.preventDefault()
                        const token = await getAccessTokenSilently()
                        await fetcher("/api/refreshQRCode", token, "PATCH")
                        setToastershow(true)
                    }} className="btn btn-danger">Rotate QR code</button>
                <Toaster show={toastershow} setShow={setToastershow} qrCodeLink={<QRCodeLink/>}/>
                </div>
            </div>
        );
    }
};



