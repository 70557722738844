import Nav from 'react-bootstrap/Nav';
import {useAuth0} from "@auth0/auth0-react";

import {Container, Navbar} from "react-bootstrap";
import { Link } from 'react-router-dom';
import {LogoutButton} from "../../buttons/LogoutButton";
import {LoginButton} from "../../buttons/LoginButton";
import logo from '../../../images/logo192.png';
function NavBar() {
    const {isAuthenticated} = useAuth0();
/*    const [key, setKey] = useState("dashboard");*/

    return <Navbar className="p-lg-4 fs-5" bg="dark" variant="dark">
        <Container>
            <Navbar.Brand><img alt="" width="30" height="30" className="d-inline-block align-text-center m-2" src={logo} />QBox</Navbar.Brand>
            <Nav
               /* activeKey={key}*/
/*                variant="pills"*/
/*                onSelect = {(eventKey) => {
                    if (eventKey != null){
                        setKey(eventKey);
                    }
                }}*/
            >
                <Nav.Item>
                    <Nav.Link eventKey="dashboard" as={Link} to="/dashboard">Dashboard</Nav.Link>
                </Nav.Item>


                <Nav.Item>
                    <Nav.Link eventKey="user" as={Link} to="/settings">Settings</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="howto" as={Link} to="/howto">How to use</Nav.Link>
                </Nav.Item>
            </Nav>

            <Navbar.Collapse className="justify-content-end">
                {isAuthenticated && <Nav.Item>
                        <LogoutButton/>
                    </Nav.Item>}
                {!isAuthenticated && <>
                        <Nav.Item>
                            <LoginButton/>
                        </Nav.Item>
                    </>}
            </Navbar.Collapse>
        </Container>
    </Navbar>;
}

export default NavBar;
