import React from "react";
import {PageLayout} from "../components/PageLayout";

export const NotFoundPage = () => {
  return (

     <PageLayout>
         <div className="container mt-2 ">
             <h1>Nothing found here 🤷🔍</h1>
             <p>Go back to dashboard: <a href="/dashboard">Dashboard</a></p>
         </div>
     </PageLayout>
  );
};
