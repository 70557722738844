
import "./Circle.css"
type Props = {
    id: string
}
const CircleWithNumber = (props: Props) => {
    // Since uuid is hex already, we can pick the first 6 and it is actually an unique color
    const color = props.id.slice(0,6)
    const style = {
        "background": `${colorShade(color,-20)}`
    }
    return (
        <div className="circle" style={style}>
            <span className="fs-3">
            {getShortID(props.id)}
            </span>
        </div>
    )
}
// Picking out some values from uuid to get a shorter version.
// There is a risk of collisions, but very low.
function getShortID(id: string | undefined): string {
    if (id === undefined) {
        return ""
    }
    return id.substring(0, 1) + id.substring(24, 25)
}

// Shades a given hex by some amount. This is to maintain contrsat with white color.
const colorShade = (col:string, amt:number) => {
    col = col.replace(/^#/, '')
    if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

    // @ts-ignore
    let [r, g, b] = col.match(/.{2}/g);
    ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

    r = Math.max(Math.min(255, r), 0).toString(16)
    g = Math.max(Math.min(255, g), 0).toString(16)
    b = Math.max(Math.min(255, b), 0).toString(16)

    const rr = (r.length < 2 ? '0' : '') + r
    const gg = (g.length < 2 ? '0' : '') + g
    const bb = (b.length < 2 ? '0' : '') + b

    return `#${rr}${gg}${bb}`
}
export default CircleWithNumber
