const fetcher = (path: string, accessToken: string, method="GET") => fetch(`${process.env.REACT_APP_API_SERVER_URL}${path}`, {
    method: method,
    headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${accessToken}`
    }
})
    .then((response) => {
        if (!response.ok) {
            throw new Error(
                `This is an HTTP error: The status is ${response.status}`
            );
        }
        return response
    })

export default fetcher
