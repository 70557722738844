import NavBar from "./navigation/desktop/NavBar";
import React, {ReactNode} from "react";
import Footer from "./core/Footer";
import "./PageLayout.css"

type Props = {
    className?: string
    children: ReactNode
}

export const PageLayout = (props: Props) => {
    return (
        <div>
            <div className="page-layout">
                <NavBar/>
                <div className="mt-5">{props.children}</div>
            </div>
            <Footer></Footer>
        </div>
    );
};
