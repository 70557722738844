import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer: React.FC = () => {
    return (
        <footer className="mt-auto bg-dark text-light footer">
            <Container className="py-5">
                <Row>
                    <Col lg={4} className="mb-4 mb-lg-0">
                        <h5>About Us</h5>
                        <p className="text-muted">
                            A company with one goal: Make it simple to handle queues, and let customers keep doing what they do until its their turn.
                        </p>
                    </Col>
                    <Col lg={4} className="mb-4 mb-lg-0">
                        <h5>Contact Us</h5>
                        <ul className="list-unstyled text-small">
                            <li><i className="bi bi-geo-alt-fill me-2"></i>0554, Oslo</li>
                            <li><i className="bi bi-envelope-fill me-2"></i><a href="mailto:mildner.arvid@gmail.com">mildner.arvid@gmail.com</a></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <div className="bg-light text-dark py-3">
                <Container>
                    <small className="text-muted">
                        &copy; {new Date().getFullYear()} QBox. All rights reserved.
                    </small>
                </Container>
            </div>
        </footer>
    );
};

export default Footer
