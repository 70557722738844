import {ToastContainer} from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import qrCode from "../images/qr.png";
import React from "react";

const Toaster = (props : {show: boolean | undefined, setShow: (arg0: boolean) => void, qrCodeLink: React.ReactNode}) => {

    return (
        <ToastContainer containerPosition="">
            <Toast onClose={() => props.setShow(false)} show={props.show} delay={3000}>
                <Toast.Header>

                    <strong className="me-auto">Updated QR code</strong>
                    <small>Just now</small>
                </Toast.Header>
                <Toast.Body className={'Sucess' && 'text-dark'}>
                    <img
                        src={qrCode}
                        width={60}

                        className="rounded me-2"
                        alt="QR code"
                    />
                    QR code was updated: {props.qrCodeLink}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
}
export default Toaster
