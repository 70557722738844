import {useQuery} from "react-query";
import fetcher from "../../utils/useApi";
import {useAuth0} from "@auth0/auth0-react";
import React from "react";
import {Spinner} from "react-bootstrap";

interface TRestaurantData {
    id: string
    restaurant_identifier: string
    subscription_level: string
}
const QRCodeLink = () => {
    const {getAccessTokenSilently} = useAuth0()
    const {isLoading: QRIsLoading, data, error} = useQuery<TRestaurantData, Error>(['/api/restaurant'], async () => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: 'https://api.qbox.no',
                scope: "admin:queue",
            },
        });
        return await fetcher("/api/restaurant", accessToken).then((res) => res.json())
    })
    if (QRIsLoading || error) {
        return (<Spinner></Spinner>)
    }
    return (
        <a
           href={`${process.env.REACT_APP_QR_FRONTEND_DOMAIN}/qrview/${data!.restaurant_identifier}`}
           target="_blank"
           rel="noreferrer noopener">here</a>)
}

export default QRCodeLink
